import axios from 'axios'
import parse from 'html-react-parser'
import Image from 'next/image'
import Link from 'next/link'
import React, { FocusEvent, FormEvent, useCallback, useRef, useState } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useTimeSince } from '@desktop/notifications/useTimeSince'
import { useRequest } from '@hooks/useRequest'
import IconArrowMin from '@icons/IconArrowMin.svg'
import IconSpeechBubble from '@icons/IconSpeechBubble.svg'
import { pluralize } from '@lib/StringHelper'
import UrlHelper from '@lib/UrlHelper'
import { IntoUrl } from '@models/IntoUrl'
import { UrlComment } from '@models/UrlCommentsResponse'
import MixMix from '@services/MixMix'

const TW_SIZE_FACTOR = 4

export const UrlCommentsList = ({ url }: { url: IntoUrl }) => {
  const { currentUser } = useCurrentUserContext()
  const { data, mutate } = useRequest(MixMix.url.getComments({ url_id: url.url_id }))
  const [isExpanded, setIsExpanded] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [replyComment, setReplyComment] = useState<UrlComment | undefined>(undefined)
  const inputContainerRef = useRef<HTMLDivElement | null>(null)

  const UrlCommentItem = ({
    comment,
    imageSize,
    onReplyClick,
  }: {
    comment: UrlComment
    imageSize: number
    onReplyClick: (comment: UrlComment) => void
  }) => {
    const timeSince = useTimeSince(comment.created_at)
    const sizeClass = `size-${imageSize / TW_SIZE_FACTOR}`
    const isOwnComment = comment.user_id === currentUser?.user_id

    const onDelete = useCallback(async () => {
      if (comment.replies?.length) {
        await Promise.all(
          comment.replies.map(reply =>
            axios.request(
              MixMix.url.deleteComment({
                commentID: reply.id,
              })
            )
          )
        )
      }
      await axios.request(
        MixMix.url.deleteComment({
          commentID: comment.id,
        })
      )
      await mutate()
    }, [comment])

    return (
      <div className="flex space-x-3">
        <Link className="shrink-0" href={UrlHelper.userPath(comment.username)}>
          <Image
            className={`inline-block aspect-square shrink-0 rounded-full bg-tertiary object-cover ${sizeClass}`}
            src={comment.photo_url ?? ''}
            alt={comment.username ?? ''}
            height={imageSize}
            width={imageSize}
          />
        </Link>
        <div className="w-full space-y-4">
          <div className="group w-full space-y-1 text-xs leading-tight">
            <div className="flex items-center justify-between">
              <Link href={UrlHelper.userPath(comment.username)} className="font-medium opacity-50">
                {comment.display_name}
              </Link>
              <div className="flex space-x-2">
                {isOwnComment ? (
                  <button onClick={onDelete} className="text-red-500 opacity-0 group-hover:opacity-100">
                    Delete
                  </button>
                ) : (
                  <button
                    onClick={() => onReplyClick(comment)}
                    className="text-accent opacity-0 group-hover:opacity-100"
                  >
                    Reply
                  </button>
                )}
                <div className="text-[#9B999A]">{timeSince}</div>
              </div>
            </div>
            <div className="text-comment">{parse(comment.text)}</div>
          </div>
          {comment.replies?.map(reply => (
            <UrlCommentItem
              key={reply.id}
              onReplyClick={() => {
                onReplyClick(comment)
                if (inputRef.current) inputRef.current.value = `@${reply.username} `
              }}
              comment={reply}
              imageSize={24}
            />
          ))}
        </div>
      </div>
    )
  }

  const insertEmoji = (emoji: string) => {
    if (inputRef.current) {
      inputRef.current.value += emoji
      inputRef.current.focus()
    }
  }

  const onReplyClick = (comment: UrlComment) => {
    setReplyComment(comment)
    inputRef.current?.focus()
  }

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      if (!inputRef.current) return
      await axios.request(
        MixMix.url.postComment({
          url_id: url.url_id,
          text: inputRef.current.value,
          parent_id: replyComment?.id,
        })
      )
      inputRef.current.value = ''
      inputRef.current.blur()
      await mutate()
    },
    [url.url_id, replyComment, mutate]
  )

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      const nextFocusedElement = event.relatedTarget
      if (!inputContainerRef.current?.contains(nextFocusedElement)) {
        setReplyComment(undefined)
      }
    },
    [setReplyComment]
  )

  return (
    <div className="rounded-xl bg-contrast/10 p-2">
      <button onClick={() => setIsExpanded(!isExpanded)} className="flex w-full items-center justify-between pr-2">
        <div className="flex items-center text-[13px] font-medium leading-snug tracking-wide">
          <IconSpeechBubble className="opacity-50" />
          <div>{data?.meta.total && data.meta.total > 0 ? pluralize(data.meta.total, 'Comment') : 'Comments'}</div>
        </div>
        <IconArrowMin className={`size-3 ${isExpanded ? 'rotate-90' : '-rotate-90'} mr-2 opacity-50 transition-all`} />
      </button>
      {isExpanded && (
        <>
          <div className="space-y-4 p-4">
            {data?.items.map(comment => (
              <UrlCommentItem key={comment.id} onReplyClick={onReplyClick} comment={comment} imageSize={36} />
            ))}
          </div>
          <div ref={inputContainerRef} className="space-y-3 border-t-[0.5px] border-primary/10 p-2 pt-4">
            <div className="flex justify-center text-center text-[20px]">
              <button className="w-12 cursor-pointer" onClick={() => insertEmoji('😄')}>
                😄
              </button>
              <button className="w-12 cursor-pointer" onClick={() => insertEmoji('🥰')}>
                🥰
              </button>
              <button className="w-12 cursor-pointer" onClick={() => insertEmoji('😂')}>
                😂
              </button>
              <button className="w-12 cursor-pointer" onClick={() => insertEmoji('😮')}>
                😮
              </button>
              <button className="w-12 cursor-pointer" onClick={() => insertEmoji('🔥')}>
                🔥
              </button>
              <button className="w-12 cursor-pointer" onClick={() => insertEmoji('😱')}>
                😱
              </button>
              <button className="w-12 cursor-pointer" onClick={() => insertEmoji('@')}>
                @
              </button>
            </div>
            <div className="flex space-x-3">
              <Image
                key={currentUser?.user_id}
                className="inline-block aspect-square size-9 shrink-0 rounded-full bg-tertiary object-cover"
                src={currentUser?.photo_url ?? ''}
                alt={currentUser?.display_name ?? ''}
                height={36}
                width={36}
              />
              <form className="w-full" onSubmit={onSubmit}>
                <input
                  ref={inputRef}
                  type="text"
                  onBlur={handleBlur}
                  placeholder={
                    replyComment
                      ? 'Replying to ' + replyComment.display_name
                      : data?.meta.total && data.meta.total > 0
                        ? 'Add a comment...'
                        : 'Be the first to comment...'
                  }
                  className="h-9 w-full rounded-full border-none bg-input p-3 text-[15px] placeholder:text-input"
                />
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
