import ReactPlayer from 'react-player'

import { IntoUrl } from '@models/IntoUrl'
import { globals } from './messages/protobuf'

interface IVideoUrl {
  isVideo: boolean
  videoUrl: string
  provider: string
  resolvedMediaType: globals.MediaType
}

interface IMediaProvider {
  name: string
  type: globals.MediaType
  test: (url: IntoUrl) => boolean | string
  resolve: (url: IntoUrl) => string
}

const DOMAIN_TESTS = {
  tiktok: 'tiktok.com',
  pinterest: ['pinterest.com'],
  youtube: ['youtube.com', 'youtube-nocookie.com', 'youtu.be'],
  vimeo: ['vimeo.com', 'player.vimeo.com'],
  imgur: ['imgur.com', 'i.imgur.com'],
  gfycat: ['gfycat.com'],
  twitch: ['twitch.tv'],
  soundcloud: ['soundcloud.com', 'm.soundcloud.com'],
}

const findMediaProvider = (
  url: IntoUrl
): { name: string; resolve: (url: IntoUrl) => string; type: globals.MediaType } => {
  const foundProvider = MEDIA_EMBED_PROVIDERS.find(provider => provider.test(url))

  return {
    name: foundProvider ? foundProvider.name : '',
    resolve: foundProvider ? foundProvider.resolve : () => '',
    type: foundProvider ? foundProvider.type : globals.MediaType.UNKNOWN_MEDIA_TYPE,
  }
}

const isGif = (url: string | undefined): boolean => {
  const GIF_REGEX = /\.gif(?!v)($|\?)/
  if (!url) return false
  return GIF_REGEX.test(url)
}

const isTikTokVideoUrl = (url: IntoUrl): boolean => {
  const USERNAME_PART_INDEX = 1
  const DIGITS_PART_INDEX = 3
  const REQUIRED_PARTS_LENGTH = 4
  const HOSTNAME_SEGMENTS_TO_JOIN = -2

  const parsedURL = (() => {
    try {
      return new URL(url.meta.provider.sourceURL ?? url.url)
    } catch (_e) {
      return new URL(url.url)
    }
  })()

  const parts = parsedURL.pathname.split('/')
  const usernamePattern = /@\w+/
  const digitsPattern = /\d+/

  return (
    parsedURL.hostname.split('.').slice(HOSTNAME_SEGMENTS_TO_JOIN).join('.') === DOMAIN_TESTS.tiktok &&
    parts?.length === REQUIRED_PARTS_LENGTH &&
    !!usernamePattern.exec(parts[USERNAME_PART_INDEX]) &&
    !!digitsPattern.exec(parts[DIGITS_PART_INDEX])
  )
}

const getTikTokVideoUrl = (url: IntoUrl) => {
  try {
    return url.meta.provider.sourceURL ?? url.url
  } catch (_e) {
    return url.url
  }
}

const testDomain = (domains: string[]) => {
  return (url: IntoUrl) => {
    const sourceURL = url.meta.provider.sourceURL
    if (sourceURL) {
      try {
        const { host } = new URL(sourceURL)
        const foundInSourceURL = domains.some(domain => host.includes(domain))
        if (foundInSourceURL) {
          return true
        }
      } catch (_e) {
        // NOOP – Ignored
      }
    }
    return domains.includes(url.hostname)
  }
}

const testAll = (...predicates: ((url: IntoUrl) => unknown)[]) => {
  return (url: IntoUrl) => predicates.reduce((result, current) => result && !!current(url), true)
}

const getRawMedia = (url: IntoUrl) => {
  const prioritizedUrls = [
    url.meta.media?.[0]?.hls_url ?? '',
    url.meta.media?.[0]?.mp4_url ?? '',
    url.meta.provider.sourceURL ?? '',
    url.url,
  ]

  for (const potentialUrl of prioritizedUrls) {
    if (potentialUrl && (ReactPlayer.canPlay(potentialUrl) || isGif(potentialUrl))) {
      return potentialUrl
    }
  }
  return ''
}

const getRawMediaPinterest = (url: IntoUrl) => {
  const firstMedia = url.meta.media?.[0] ?? {}

  const { mp4_url: mp4Url, hls_url: hlsUrl } = firstMedia
  if (mp4Url) {
    return mp4Url
  }
  // HLS videos from Pinterest don't play on mix.com.
  // https://app.asana.com/0/226524042134185/1204409737717556/f
  if (hlsUrl) {
    // https://v1.pinimg.com/videos/mc/hls/9e/f4/d6/9ef4d6324a5674c89bf7cd06198c9e74.m3u8
    // -> https://v1.pinimg.com/videos/mc/720p/9e/f4/d6/9ef4d6324a5674c89bf7cd06198c9e74.mp4
    // V2 urls look like this:
    // https://v1.pinimg.com/videos/v2/hls/0b/dc/10/0bdc10dbcbecdfc07eb6b2071e41579d_mobile.m3u8
    // -> https://v1.pinimg.com/videos/720p/0b/dc/10/0bdc10dbcbecdfc07eb6b2071e41579d.mp4

    return hlsUrl.replace('v2/hls', '720p').replace('hls', '720p').replace('_mobile.', '.').replace('m3u8', 'mp4')
  }
  return ''
}

const testRawMediaPinterest = (url: IntoUrl) => {
  const firstMedia = url.meta.media?.[0] ?? {}

  const { mp4_url: mp4Url, hls_url: hlsUrl } = firstMedia
  let videoUrl = ''
  if (mp4Url) {
    videoUrl = mp4Url
  } else if (hlsUrl) {
    videoUrl = hlsUrl.replace('hls', '720p').replace('m3u8', 'mp4')
  }

  return ReactPlayer.canPlay(videoUrl)
}

const testYoutubeVideoId = (url: IntoUrl) => {
  const YOUTUBE_SHORT_URL_HOSTNAME = 'youtu.be'
  const YOUTUBE_SHORT_URL_REGEX = /^\/(\w+)$/
  const YOUTUBE_LONG_URL_REGEX = /^\/([^/]+)\/(\w+)$/

  const SHORT_URL_GROUP_INDEX = 1
  const LONG_URL_GROUP_INDEX = 2
  const parsedURL = (() => {
    try {
      return new URL(url.meta.provider.sourceURL ?? url.url)
    } catch (_e) {
      return new URL(url.url)
    }
  })()

  if (parsedURL.hostname === YOUTUBE_SHORT_URL_HOSTNAME) {
    const match = YOUTUBE_SHORT_URL_REGEX.exec(parsedURL.pathname)
    return match ? match[SHORT_URL_GROUP_INDEX] : null
  }

  if (YOUTUBE_LONG_URL_REGEX.test(parsedURL.pathname)) {
    const match = YOUTUBE_LONG_URL_REGEX.exec(parsedURL.pathname)
    return match ? match[LONG_URL_GROUP_INDEX] : null
  }

  return parsedURL.searchParams.get('v')
}

const testVimeoEmbedUrl = (url: IntoUrl) => {
  const VIMEO_EMBED_URL_REGEX = /\/(\d+)\/?$/

  const { pathname } = new URL(url.url)
  return VIMEO_EMBED_URL_REGEX.exec(pathname)?.[1]
}

const testGfycatEmbedUrl = (url: IntoUrl) => {
  const GFYCAT_EMBED_URL_REGEX1 = /^\/(\w+)(-[\w-]+)?\/?$/
  const GFYCAT_EMBED_URL_REGEX2 = /^\/ifr\/(\w+)\/?$/

  const { pathname } = new URL(url.url)
  return GFYCAT_EMBED_URL_REGEX1.exec(pathname)?.[1] || GFYCAT_EMBED_URL_REGEX2.exec(pathname)?.[1]
}

const testTwitchEmbedUrl = (url: IntoUrl) => {
  const TWITCH_VIDEO_ID_REGEX = /^\/videos\/(\d+)$/
  const TWITCH_CLIP_SLUG_REGEX = /^\/\w+\/clip\/(\w+)$/

  const { pathname } = new URL(url.url)
  const videoIdMatch = TWITCH_VIDEO_ID_REGEX.exec(pathname)
  const clipSlugMatch = TWITCH_CLIP_SLUG_REGEX.exec(pathname)

  if (videoIdMatch) {
    return false
  }
  return clipSlugMatch
}

export enum PROVIDER_NAMES {
  RAW_VIDEO_PINTEREST = 'raw_video_pinterest',
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  IMGUR = 'imgur',
  GFYCAT = 'gfycat',
  TWITCH = 'twitch',
  SOUNDCLOUD = 'soundcloud',
  TIKTOK = 'tiktok',
  RAW_VIDEO = 'raw_video',
}

const MEDIA_EMBED_PROVIDERS: IMediaProvider[] = [
  {
    name: PROVIDER_NAMES.RAW_VIDEO_PINTEREST,
    type: globals.MediaType.VIDEO,
    test: testAll(testDomain(DOMAIN_TESTS.pinterest), testRawMediaPinterest),
    resolve: getRawMediaPinterest,
  },
  {
    name: PROVIDER_NAMES.YOUTUBE,
    type: globals.MediaType.VIDEO,
    test: testAll(testDomain(DOMAIN_TESTS.youtube), testYoutubeVideoId),
    resolve: getRawMedia,
  },
  {
    name: PROVIDER_NAMES.VIMEO,
    type: globals.MediaType.VIDEO,
    test: testAll(testDomain(DOMAIN_TESTS.vimeo), testVimeoEmbedUrl),
    resolve: getRawMedia,
  },
  {
    name: PROVIDER_NAMES.IMGUR,
    type: globals.MediaType.VIDEO,
    test: testDomain(DOMAIN_TESTS.imgur),
    resolve: getRawMedia,
  },
  {
    name: PROVIDER_NAMES.GFYCAT,
    type: globals.MediaType.GIF,
    test: testAll(testDomain(DOMAIN_TESTS.gfycat), testGfycatEmbedUrl),
    resolve: getRawMedia,
  },
  {
    name: PROVIDER_NAMES.TWITCH,
    type: globals.MediaType.VIDEO,
    test: testAll(testDomain(DOMAIN_TESTS.twitch), testTwitchEmbedUrl),
    resolve: getRawMedia,
  },
  {
    name: PROVIDER_NAMES.SOUNDCLOUD,
    type: globals.MediaType.AUDIO,
    test: testDomain(DOMAIN_TESTS.soundcloud),
    resolve: getRawMedia,
  },
  {
    name: PROVIDER_NAMES.TIKTOK,
    type: globals.MediaType.VIDEO,
    test: isTikTokVideoUrl,
    resolve: getTikTokVideoUrl,
  },
  {
    name: PROVIDER_NAMES.RAW_VIDEO,
    type: globals.MediaType.VIDEO,
    test: getRawMedia,
    resolve: getRawMedia,
  },
]

export const resolveMedia = (url: IntoUrl): IVideoUrl => {
  const { name: provider, resolve, ...rest } = findMediaProvider(url)
  const videoUrl = resolve(url)
  const type = isGif(videoUrl) ? globals.MediaType.GIF : rest.type
  const isVideo =
    !!videoUrl &&
    (type === globals.MediaType.VIDEO || type === globals.MediaType.GIF || type === globals.MediaType.AUDIO)
  return { isVideo, videoUrl, provider, resolvedMediaType: type }
}
